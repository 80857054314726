import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/home/runner/work/oida-is-des-org-blog/oida-is-des-org-blog/src/templates/blog-post-template.tsx";
import { ItalicText } from '../../components/italic-text/italic-text';
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <ItalicText mdxType="ItalicText">“I would like to be able to look at a mockup for a website and immediately know: This is not
accessible!”</ItalicText> – A colleague of mine expressed this wish a few days ago. He has been working for many
years as a web developer, but still has little experience with digital accessibility.
    <p>{`His question got me thinking: What can newbies do to develop a keen sense for accessibility?`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "1200px"
        }
      }}>{`
      `}<span parentName="span" {...{
          "className": "gatsby-resp-image-background-image",
          "style": {
            "paddingBottom": "56.666666666666664%",
            "position": "relative",
            "bottom": "0",
            "left": "0",
            "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAALABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAABQABBv/EABUBAQEAAAAAAAAAAAAAAAAAAAEA/9oADAMBAAIQAxAAAAFUNfU52Xi//8QAGxAAAgIDAQAAAAAAAAAAAAAAAAMBAhMiMTP/2gAIAQEAAQUCmdW1ZcwMFeVie//EABURAQEAAAAAAAAAAAAAAAAAAAAR/9oACAEDAQE/Aar/xAAVEQEBAAAAAAAAAAAAAAAAAAARAP/aAAgBAgEBPwEYv//EABkQAAEFAAAAAAAAAAAAAAAAAAACECExQf/aAAgBAQAGPwKCjBL/AP/EABwQAQABBAMAAAAAAAAAAAAAAAEAEBEhMVFhkf/aAAgBAQABPyFhu9sRvKv3G5r1GrPFBj//2gAMAwEAAgADAAAAEAT/AP/EABcRAAMBAAAAAAAAAAAAAAAAAAABESH/2gAIAQMBAT8QUYUf/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERMf/aAAgBAgEBPxCdFH//xAAaEAADAQADAAAAAAAAAAAAAAABESEAMWFx/9oACAEBAAE/EHWGyB3ALjOlOzhIB5pVGVurnAab/9k=')",
            "backgroundSize": "cover",
            "display": "block"
          }
        }}></span>{`
  `}<img parentName="span" {...{
          "className": "gatsby-resp-image-image",
          "alt": "Fingers touch the page of a book in Braille.",
          "title": "Fingers touch the page of a book in Braille.",
          "src": "/static/7e562c01339e6b9f3d0dea79455a5fd1/e5166/pexels-eren-li-fingers-reading-braille.jpg",
          "srcSet": ["/static/7e562c01339e6b9f3d0dea79455a5fd1/f93b5/pexels-eren-li-fingers-reading-braille.jpg 300w", "/static/7e562c01339e6b9f3d0dea79455a5fd1/b4294/pexels-eren-li-fingers-reading-braille.jpg 600w", "/static/7e562c01339e6b9f3d0dea79455a5fd1/e5166/pexels-eren-li-fingers-reading-braille.jpg 1200w", "/static/7e562c01339e6b9f3d0dea79455a5fd1/b17f8/pexels-eren-li-fingers-reading-braille.jpg 1600w"],
          "sizes": "(max-width: 1200px) 100vw, 1200px",
          "style": {
            "width": "100%",
            "height": "100%",
            "margin": "0",
            "verticalAlign": "middle",
            "position": "absolute",
            "top": "0",
            "left": "0"
          },
          "loading": "lazy",
          "decoding": "async"
        }}></img>{`
    `}</span>{`
`}<em parentName="p">{`Photo: © Eren Li / pexels.com`}</em></p>
    <h2>{`Responsive Design? What's that good for?`}</h2>
    <p>{`Younger colleagues may find it hard to imagine: But web content didn't always fluidly adapt to different screen sizes.
Even in the 2000s it was still common to optimize websites for specific screen sizes only. The following article provides
a good summary:
`}<a parentName="p" {...{
        "href": "https://www.freecodecamp.org/news/a-brief-history-of-responsive-web-design/"
      }}>{`“A Brief History of Responsive Web Design”`}</a>{`.`}</p>
    <p>{`This changed when smartphones became more and more popular. Today, users expect websites to look good and work on all devices.
Responsive design is explicitly defined as a requirement for web projects. UI/UX experts create designs and prototypes for
different screen sizes. Developers test implemented features on desktop and mobile devices.`}</p>
    <p>{`Now what does that have to do with accessibility? For me, the triumph of responsive design clearly shows that the more
people use websites in a certain way, the more these needs are taken into account in web development.`}</p>
    <h2>{`Accessible Design? What's that good for?`}</h2>
    <p>{`Most people use their smartphone or a computer mouse to surf the Internet.
They click a button and notice the visual feedback on the page. They're not dependent on a
`}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=dEbl5jvLKGQ"
      }}>{`screen reader being able to read the web content in a meaningful way`}</a>{`
or on `}<a parentName="p" {...{
        "href": "https://www.youtube.com/watch?v=FvpUNiB-2T0"
      }}>{`interactive elements being accessible with the keyboard`}</a>{`.`}</p>
    <p>{`Web developers are users too. Their everyday experiences influence their perception of what makes a good, usable website.
For example: If you've never used a screen reader, it's hard to imagine why the
`}<a parentName="p" {...{
        "href": "https://www.w3schools.com/html/html5_semantic_elements.asp"
      }}>{`semantic markup of web content is so important`}</a>{`.`}</p>
    <p>{`This brings me back to the central question: What can newbies do to develop a keen sense for accessibility as quickly
as possible? Very simple: `}<strong parentName="p">{`Change perspective!`}</strong></p>
    <h2>{`Take on the perspective of people with disabilities`}</h2>
    <p>{`If you want to understand the needs of people with disabilities, the best way is to just ask them how they use
digital content. Have a blind or partially sighted person show you how to shop online using a screen reader.
Talk with a deaf person about watching the news with closed captions or in sign language.`}</p>
    <p>{`If you don't know any people with disabilities, you can organize a workshop in your company, for example.
Contact an organization of persons with disabilities in your country. Some organizations also offer specific
trainings, e.g., the
`}<a parentName="p" {...{
        "href": "https://www.myability.org/loesungen/disability-trainings/sensing-journey"
      }}>{`Sensing Journey (in German)`}</a>{`.`}</p>
    <h2>{`Practice makes perfect`}</h2>
    <p>{`As a web developer, you are constantly confronted with new requirements that result from rapid technological change.
That's why we live by the principle of "learning by doing". This approach also helps us when it comes to digital
accessibility: Just do it!`}</p>
    <p>{`For a start, you should read up on the
`}<a parentName="p" {...{
        "href": "https://www.w3.org/WAI/WCAG21/quickref/"
      }}>{`"Web Content Accessibility Guidelines" (WCAG)`}</a>{`. They're already a legal
requirement for the public sector in all EU member states. The WCAG cover a wide range of needs from different user groups.
They provide very detailed online resources, with concrete examples of accessible implementations, as well as common mistakes.`}</p>
    <p>{`My recommendation: Most web content can be made accessible with semantic HTML and CSS alone. I often come across websites
that make excessive use of `}<a parentName="p" {...{
        "href": "https://developer.mozilla.org/en-US/docs/Web/Accessibility/ARIA"
      }}>{`ARIA attributes`}</a>{`, creating more
barriers than they remove. It makes sense to use ARIA for complex UI components, as illustrated by the
`}<a parentName="p" {...{
        "href": "https://www.w3.org/TR/wai-aria-practices-1.1/"
      }}>{`WAI-ARIA Authoring Practices`}</a>{`.`}</p>
    <h2>{`Take your time`}</h2>
    <p>{`Digital accessibility is complex and multi-layered. For newbies, this means a steep learning curve and a lot of potential
for frustration. You should approach the topic step by step and be open to new perspectives. Then you will develop
a keen sense for accessibility over time all by yourself.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      